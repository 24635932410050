import Swiper from 'swiper';
import {Navigation, Pagination, Controller, Autoplay, Parallax} from 'swiper/modules'
import {getAutoMargin} from "@scripts/components/root.js";


const buildSliderTestimonials = sliderElm => {
  const sliderIdentifier = sliderElm.id;

  return new Swiper(`#${sliderIdentifier}`, {
    slidesPerView: 1,
    loop: false,
    autoplay: {
      pauseOnMouseEnter: true,
        delay: 5000,
    },
    pauseOnMouseEnter: true,
    navigation: {
      nextEl: '.swiper-button-next-' + sliderIdentifier,
      prevEl: '.swiper-button-prev-' + sliderIdentifier
    },
  });
}
const buildCarouselProjects = sliderElm => {
  const sliderIdentifier = sliderElm.id;

  return new Swiper(`#${sliderIdentifier}`, {
    slidesPerView: 5.3,
    spaceBetween: 20,
    centeredSlides: false,
    slidesOffsetAfter: 20,
    slidesOffsetBefore: getAutoMargin(),
    loop: false,
    navigation: {
      nextEl: '.swiper-button-next-' + sliderIdentifier,
      prevEl: '.swiper-button-prev-' + sliderIdentifier
    },
    pagination: {
      el: '.swiper-pagination-' + sliderIdentifier,
      type: 'bullets',
    },

    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.2,
        spaceBetween: 16
      },
      // when window width is >= 480px
      768: {
        slidesPerView: 2.7,
        spaceBetween: 24
      },
      // when window width is >= 480px
      1280: {
        slidesPerView: 4.8,
        spaceBetween: 24,
        slidesOffsetBefore: getAutoMargin(),
        // slidesOffsetAfter: 'auto'
      },
      controller: {
        by: 'container'
      },
      modules: [Navigation, Pagination, Controller]
    }
  });
}

const buildCarouselLogos = sliderElm => {
  const sliderIdentifier = sliderElm.id;

  return new Swiper(`#${sliderIdentifier}`, {
    slidesPerView: 9.3,
    spaceBetween: 20,
    centeredSlides: false,
    slidesOffsetAfter: 20,
    slidesOffsetBefore: getAutoMargin(),
    loop: true,
    autoplay: {
        pauseOnMouseEnter: true,
        delay: 2200,
    },
    navigation: {
      nextEl: '.swiper-button-next-' + sliderIdentifier,
      prevEl: '.swiper-button-prev-' + sliderIdentifier
    },
    pagination: {
      el: '.swiper-pagination-' + sliderIdentifier,
      type: 'bullets',
    },

    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.4,
        spaceBetween: 16
      },
      // when window width is >= 480px
      768: {
        slidesPerView: 2.7,
        spaceBetween: 24
      },
      // when window width is >= 480px
      1280: {
        slidesPerView: 9.3,
        spaceBetween: 24,
        slidesOffsetBefore: 32,
        // slidesOffsetAfter: 'auto'
      },
      controller: {
        by: 'container'
      },
      modules: [Navigation, Pagination, Controller]
    }
  });
}


//const allSliderMedia = document.querySelectorAll('.sliderCardsCta');
const sliderTestimonials = document.querySelectorAll('.sliderTestimonials');
const carouselEntities = document.querySelectorAll('.sliderProjects');
const carouselLogos = document.querySelectorAll('.sliderLogos');
Swiper.use([Navigation, Pagination, Controller, Autoplay, Parallax]);


//allGallery.forEach(slider => buildGalleryWithThumbnail(slider));


window.addEventListener('load', (e) => {
  sliderTestimonials.forEach(slider => buildSliderTestimonials(slider));
  carouselEntities.forEach(slider => buildCarouselProjects(slider));
  carouselLogos.forEach(slider => buildCarouselLogos(slider));
})
window.addEventListener('resize', (e) => {
  sliderTestimonials.forEach(slider => buildSliderTestimonials(slider));
  carouselEntities.forEach(slider => buildCarouselProjects(slider));
  carouselLogos.forEach(slider => buildCarouselLogos(slider));
});
