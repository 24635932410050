export default function calcContainer() {
  let container = document.querySelector('.mainFooter .container')
  let styles = window.getComputedStyle(container)

  let trueContainer = container.clientWidth - parseFloat(styles.paddingLeft) - parseFloat(styles.paddingRight)

  let vw = document.body.clientWidth
  let autoMargin = (vw - trueContainer) / 2;

  console.log(autoMargin, vw, trueContainer)
  return returnContainer(autoMargin);
}

export function trueHeight() {
  // set true mobile height
  let trueHeight = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
  return document.documentElement.style.setProperty('--tHScreen', `${trueHeight}px`);
}

export function returnContainer(autoMargin) {
  document.querySelector(':root').style.setProperty('--autoMarginPx', autoMargin + 'px');
  return document.querySelector(':root').style.setProperty('--autoMargin', autoMargin);

}

export function getAutoMargin(){
  let r = document.querySelector(':root');
  return getComputedStyle(r).getPropertyValue('--autoMargin')
}


